















































import Vue from 'vue';
import dayjs from 'dayjs';
import format from '@/services/format';
import * as log from '@/services/log';

export default Vue.extend({
    name: 'ValidityTimer',

    props: ['deadline'],

    mixins: [{ methods: { ...format } }],

    data() {
        return {
            timeToExpire: -1,
            timerIntervalId: undefined as number | undefined,
        };
    },

    watch: {
        deadline(value: number) {
            // log.debug('[ValidityTimer] deadline', value);
            if (value == null) {
                this.stop();
            } else {
                this.run();
            }
        },
    },

    mounted() {
        // nop
    },

    beforeDestroy() {
        this.stop();
    },

    methods: {
        validityClass() {
            if (this.timeToExpire < 30) {
                return 'validity-critical';
            }
            if (this.timeToExpire < 120) {
                return 'validity-warning';
            }
            return 'validity-ok';
        },

        run() {
            log.debug('[ValidityTimer] run timer');
            // handle validity visualisation (refresh every 1sec)
            // this ValidityTimer reacts on :deadline parameter:
            // - when :deadline is set, it triggers a 1sec visual timer refresh
            // - when :deadline is undefined/null, it stops the visual timer refresh
            this.timerIntervalId = setInterval(() => {
                const now = dayjs().unix();
                const deadline = this.deadline as number;
                // log.debug('[ValidityTimer] validityTimer: ', now, deadline);
                if (isNaN(deadline) || now >= deadline) {
                    this.timeToExpire = -1;
                    this.stop();
                    return;
                }
                this.timeToExpire = dayjs.unix(deadline).diff(dayjs()) / 1000;
            }, 1000);
        },

        stop() {
            log.debug('[ValidityTimer] stop timer');
            clearInterval(this.timerIntervalId);
            this.timerIntervalId = undefined;
            this.timeToExpire = -1;
        },
    },
});
